import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import { Link, navigate } from 'gatsby';
import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';
// import Initiativeviewspecies from 'components/admin/initiative-view';

//import sidebar css from react-pro-sidebar module and our custom css
import 'react-pro-sidebar/dist/css/styles.css';

import 'assets/styles/pages/customheader.scss';

import AdminService from 'services/admin/admin.service';

import { useForm } from "react-hook-form";

import { Modal, Spinner } from 'react-bootstrap';

import { toast, ToastContainer } from 'react-toastify';

import PlantationListDetails from 'services/plantation/plantation.service';

import closesmall from 'assets/images/icons/close-small-icon.svg';

import imageCompression from 'browser-image-compression';

import { compressOptions } from 'assets/data/imageCompress';
import { doAdminLogout } from './login';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

export default function Editprojectpage(props) {

  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [projectEditData, setProjectEditData] = useState([])
  const [loading, setLoading] = useState([false])
  const [pathImage, setPathImage] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [positionValue, setPositionValue] = useState("");
  const [longitude, setLongitude] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [latitude, setLatitude] = useState("");
  const [description, setDescription] = useState("");
  const [emailId, setEmailId] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [statusType, setStatusType] = useState("");
  const [name, setName] = useState("");
  const [allowFiles, setAllowFiles] = useState(false);
  const [allImage, setAllImage] = useState([]);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [filesDataList, setFilesDataList] = useState([]);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [targetCount, setTargetCount] = useState('');
  const EditValues = props.data
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const saveImg = async () => {
    setLoading(true)
    const plantationResponse = await ds.imagesSignedUrl({ signedUrlDetails: signedList });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        //  setImageLoadedList(plantationResponse.data.signedUrlDetails);
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails);
  }

  const imageUploadCall = async (data) => {
    setLoading(true)
    let pathImages = []
    const resultsData = await data.map((item, index) => {

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: filesList[index],
        redirect: 'follow'
      };
      setLoading(true)
      return fetch(item.signedUrl, requestOptions).then(result => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImages.push(obj);
      })
    })
    return Promise.all(resultsData).then(() => {
      finalCall(pathImages)
    }).catch(e => {
      toast.error("Something went wrong")
      setLoading(false);
    })
  }

  const finalCall = (data) => {
    setLoading(true);
    let formData = {
      "projectId": EditValues.id,
      "projectStartDate": new Date(projectStartDate),
      "description": description,
      "phoneNumber": phoneNumber,
      "email": emailId,
      "isLeafProject": false,
      "parentProjectId": 0,
      "pictureDetails": data,
      "latitude": latitude,
      "longitude": longitude,
      "status": statusType,
      "targetCount": targetCount,
    }
    adminApi.editProject(formData).then(data => {
      if (data.status === 200) {
        toast.success("Project Details Updated successfully");
        pathImage.length = 0;
        setSignedList([]);
        setTimeout(function () {
          navigate("/admin/projectstatus")
          setLoading(false)
        }, 2000)
      } else {
        toast.error("Something went wrong");
        setLoading(false)
      }
    }).catch(error => {
      pathImage.length = 0;
      setSignedList([]);
      setLoading(false)
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Authentication failed");
          navigate("/")
        } else {
          toast.error(error.response.data.message);
        }
      }
    })
  }
  const fileLoad = async (e) => {
    let testFile = e.target.files[0]
    const reader = new FileReader();
    let fileExtension = testFile.type.split("/").pop();
    const compressedFile = await imageCompression(testFile, compressOptions);
    if (fileExtension !== "png" && fileExtension !== "jpg" && fileExtension !== "jpeg" && fileExtension !== "gif") {
      setTypeError(true)
      setFileError(false)
    } else if (compressedFile.size > 2e+6) {
      setSizeError(true)
      setFileError(false)
      setTypeError(false)
    } else {
      setLoading(true)
      if (e.target.files) {

        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf('.');

        const fileName = name.substring(0, lastDot);
        const file = e.target.files[0];
        const filetype = e.target.files[0].type;
        let fileExtension = file.type.split("/").pop();
        let imgData = {
          "fileName": fileName,
          "fileExtension": fileExtension,
          "fileType": filetype
        }
        const filesData = [
          ...filesList,
          compressedFile,
        ]
        const signedData = [
          ...signedList,
          imgData,
        ]
        setFilesList(filesData);
        setFilesDataList([...filesDataList, e.target.files[0]])
        //   setAllImage([...allImage, URL.createObjectURL(e.target.files[0])]);
        setSignedList(signedData)
        setFileError(false)
        setLoading(false)
        setTypeError(false);
        setSizeError(false);
      }
    }
  }

  const phoneNumberChange = (data) => {
    var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (data.match(phoneNum)) {
      setPhoneNumber(data);
      setPhoneError(false);
    }
    else {
      setPhoneNumber(data)
      setPhoneError(true);
    }
  }
  const emailChange = (data) => {
    var phoneNum = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.match(phoneNum)) {
      setEmailId(data);
      setEmailError(false);
    }
    else {
      setEmailId(data)
      setEmailError(true);
    }
  }

  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray)
    infoArray = signedList.filter(function (obj) {
      const lastDot = e.currentTarget.id.lastIndexOf('.');
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray)
  }
  const onSubmit = async () => {
    if (name !== "" && emailId !== "" && phoneNumber !== "" && latitude !== "" && longitude !== "" && projectStartDate !== "" && description !== "" && fileError === false && phoneError === false && emailError === false && targetCount !== "") {
      if (filesDataList.length === 0) {
        setLoading(true)
        let formData = {
          "name": name,
          "projectId": EditValues.id,
          "projectStartDate": new Date(projectStartDate),
          "description": description,
          "phoneNumber": phoneNumber,
          "email": emailId,
          "isLeafProject": false,
          "parentProjectId": 0,
          "pictureDetails": [],
          "latitude": latitude,
          "longitude": longitude,
          "status": statusType,
          "targetCount": targetCount,
        }
        adminApi.editProject(formData).then(data => {
          if (data.status === 200) {
            toast.success("Project Details Updated successfully");
            pathImage.length = 0;
            setSignedList([]);
            setTimeout(function () {
              navigate("/admin/projectstatus")
              setLoading(false)
            }, 2000)
          } else {
            toast.error("Something went wrong");
            setLoading(false)
          }
        }).catch(error => {
          pathImage.length = 0;
          setSignedList([]);
          setLoading(false)
          if (error.response) {
            if (error.response.status === 401) {
              toast.error("JWT Token has Expired");
              navigate("/")
            } else {
              toast.error(error.response.data.message);
            }
          }
        })
      } else {
        await saveImg()
      }
    }
  }
  const loadProjectData = async () => {
    setLoading(true);
    await adminApi.getProjects(EditValues.id).then(data => {
      if (data.status === 200) {
        setProjectEditData(data.data);
        setName(data.data.name);
        setProjectStartDate(data.data.projectStartDate);
        setDescription(data.data.description);
        setTargetCount(data.data.targetCount);
        setLatitude(data.data.latitude);
        setLongitude(data.data.longitude);
        setPhoneNumber(data.data.phoneNumber);
        setEmailId(data.data.email);
        setStatusType(data.data.status === "ACTIVE" ? 2 : 1);
        setAllImage(data.data.projectImageUrls);
        setLoading(false)
      } else {
        setLoading(false)
        toast.error("Something went wrong");
      }
    }).catch(error => {
      setLoading(false)
      if (error.response) {
        if (error.response.status === 401) {
          doAdminLogout()
          toast.error("Authentication failed");
          navigate("/admin/")
        } else {
          toast.error(error.response.data.message);
        }
      }
    });
  };
  const statusChange = (e) => {
    setStatusType(e.target.value)
  }
  useEffect(() => {
    if (EditValues !== null) {
      loadProjectData();
    }
  }, []);
  return (
    <>
      <div className='admin-breadcumb-item'>
        <span>
          <Link className='' to='/admin/dashboardview/'> <img src={treeicon} alt='treeicon' /></Link>
        </span>
        <span>
          <img src={breadcumbarrow} alt='arrowicon' />
        </span>
        <h3> {projectEditData.name} </h3>
        {/* <span>
          <img src={breadcumbarrow} alt='arrowicon' />
        </span>
        <h3> Edit </h3> */}
      </div>
      <div className='white-iitiative-wrapper'>
        <div className=''>
          <h3 className='sub-heading-title'>EDIT PROJECT DETAILS </h3>
        </div>

        <div className='register-plantation'>
          <div className='container'>
            <div className='row'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='name' className='form-label'>
                        Project Name <i className="hastric-color">*</i>
                      </label>
                      <input
                        type='text'
                        name='projectname'
                        className='form-control '
                        id='projectname'
                        placeholder='Green hanha'
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                      {name === "" ? <span className="form-error">
                        Project Name is Required
                      </span> : null}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='date' className='form-label'>
                        Project Start Date <i className="hastric-color">*</i>
                      </label>
                      <input
                        type='date'
                        name='date'
                        className='form-control '
                        id='date'
                        value={(new Date(projectStartDate)).toLocaleDateString('en-CA')}
                        dateformat="dd-mm-yyyy"
                        onChange={(e) => { setProjectStartDate(e.target.value) }}
                      />
                      {projectStartDate === "" ? <span className="form-error">
                        Project Date is Required
                      </span> : null}
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='fc-wrapper'>
                      <label htmlFor='name' className='form-label'>
                        Project Description <i className="hastric-color">*</i>
                      </label>
                      <textarea
                        type='textarea'
                        name='description'
                        className='form-control '
                        id='description'
                        onChange={(e) => { setDescription(e.target.value) }}
                        value={description}
                      />
                      {description === "" ? <span className="form-error">
                        Description is Required
                      </span> : null}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='latitude' className='form-label'>
                        Latitude <i className="hastric-color">*</i>
                      </label>
                      <input type='text' name='latitude'

                        value={latitude}
                        onChange={(e) => { setLatitude(e.target.value) }}

                        className='form-control ' id='latitude' placeholder='' />
                      {latitude === "" ? <span className="form-error">
                        Latitude is Required
                      </span> : null}

                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='name' className='form-label'>
                        Longitude <i className="hastric-color">*</i>
                      </label>
                      <input type='text' name='longitude'

                        value={longitude}
                        onChange={(e) => { setLongitude(e.target.value) }}
                        className='form-control ' id='longitude' placeholder='' />
                      {longitude === "" ? <span className="form-error">
                        Longitude is Required
                      </span> : null}
                    </div>
                  </div>

                  <div className='col-md-12 mg-top30'>
                    <h3 className='sub-heading-title'> ADD IMAGES </h3>

                    <div className='dis-flex cent-item-mob add-more-img-posi'>
                      {/* {allImage && allImage.map(data => {
                        return (<img src={data} className='img-fluid' alt='Add image List' width="140px" height="70px" />)

                      })} */}


                      <SimpleReactLightbox>
                        <SRLWrapper>

                          {allImage && allImage.map(data => {
                            return (
                              <div className='recent-plantation-section-plantation'>
                                <div className='img-wrapper img_wrp hand-pointer' key={data}>
                                  <img src={data} className='img-fluid' alt='' width="140px" height="70px" />
                                  <div className='overlay overLay-img'>
                                    <i className='icon icon-arrows-fullscreen'></i>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </SRLWrapper>
                      </SimpleReactLightbox>
                      {filesList && filesList.map(data => {
                        return (<div className='img_wrp'>
                          <span className='small-close small-close-images hand-pointer close' >
                            <div id={data.name} onClick={imageRemove}> <img src={closesmall} className='img-fluid' alt='Close small' />{' '}</div>
                          </span>
                          <img src={URL.createObjectURL(data)} className='img-fluid' alt='Add image List' width="140px" height="70px" />
                        </div>)

                      })}

                      <div className='border-items margin-left-11'>
                        <span className='plus-icon-green btn-file'>
                          <i className='icon icon-plus-lg plus-symbol-custom-icon'></i>
                          <input type="file" onChange={fileLoad} disabled={filesList.length === 5} accept="image/png, image/gif, image/jpeg" />Add more
                        </span>
                      </div>
                    </div>
                    <div>
                      {sizeError === true ?
                        <span className="form-error">
                          Please upload a file smaller than 2 MB
                        </span>
                        : null}
                      {typeError === true ?
                        <span className="form-error">
                          File does not support. You must use .png or .jpg
                        </span>
                        : null}
                      {fileError === true ? <span className="form-error">Add at least one Image</span> : null}
                    </div>
                  </div>

                  <h3 className='sub-heading-title'> CONTACT </h3>

                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='phoneNumber' className='form-label'>
                        Phone Number <i className="hastric-color">*</i>
                      </label>
                      <input
                        type='number'
                        name='phoneNumber'
                        className='form-control '
                        id='phoneNumber'
                        onChange={(e) => { phoneNumberChange(e.target.value) }}
                        // {...register("phoneNumber", {
                        //   required: "Phone Number is required",
                        //   pattern: {
                        //     value: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/,
                        //     message: "Please enter a valid Mobile Number",
                        //   },
                        // })}
                        value={phoneNumber}
                      />
                      {phoneError === true ? <span className="form-error">Please enter a valid Number</span> : null}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='name' className='form-label'>
                        Email ID <i className="hastric-color">*</i>
                      </label>
                      <input
                        type='email'
                        name='email'
                        className='form-control '
                        id='email'
                        value={emailId}
                        onChange={(e) => { setEmailId(e.target.value) }}

                      />
                      {emailChange === true ? <span className="form-error">Please enter a valid email</span> : null}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='targetCount' className='form-label'>
                        Target Count <i className="hastric-color">*</i>
                      </label>
                      <input
                        type='number'
                        name='targetCount'
                        className='form-control '
                        id='targetCount'
                        value={targetCount}
                        onChange={(e) => { setTargetCount(e.target.value) }}

                      />
                      {targetCount === '' ? <span className="form-error">Please enter Target Count</span> : null}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fc-wrapper'>
                      <label htmlFor='statusType' className='form-label'>
                        Status <i className="hastric-color">*</i>
                      </label>
                      <select name='statusType' id='statusType' value={statusType} onChange={(e) => { statusChange(e) }} className='form-control'>
                        <option value='2'>Active</option>
                        <option value='1'> Inactive </option>
                        <option value='3'> Completed </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='text-right'>
                  <Link to='/admin/projectstatus' className='btn btn-cancel mg-right10 margin-btm-10'>
                    Cancel
                  </Link>

                  <button className='btn btn-viewmore save-width'>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
