import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Editprojectpage from 'components/admin/editprojectpage';

const Editproject = ({ location }) => {
  const dataValue = (location.state && location.state.editData !== undefined) ? location.state.editData : null;
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Editprojectpage data={dataValue} />
        </div>
      </AdminLayout>
    </>
  );
};

export default Editproject;
